let countUp = 1,
	countDown = 1;

function getValues(e) {
    var input = document.querySelector('.guest-counter');
    var count = parseInt(input.value);

    var selected = retrieve('selected');
    var resources = retrieve('resources');
    var capacity = resources[selected.id].B25__MaxCapacity__c;

    return {
        input: input,
        count: count,
        capacity: capacity
    }
}

function stepUp(e) {
    e.preventDefault();
    var { count, capacity, input } = getValues(e);

    count = count + 1;
    if(capacity && count > capacity) {
        document.querySelector('.guest-notification')
            .classList.add('--notification-active')
    } else {
        input.value = count;
    }
}

function stepDown(e) {
    e.preventDefault();
    var { count, capacity, input } = getValues(e);

    count = --count <= 0 ? 1 : count;
	if (capacity && count < capacity) {
        document.querySelector('.guest-notification')
            .classList.remove('--notification-active')
	}

    input.value = count;
}
